import { useEffect, useMemo, useState } from 'react';

import { Button, MenuProps as AntMenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthContext';
import { useTheme } from 'src/contexts/ThemeContext';
import { RoutesMapKeys, useAppNavigation } from 'src/routes';
import { useGlobal } from 'src/contexts/GlobalContext';

import LogoCollapsed from '@/assets/logo-collapsed.svg?url';
import Logo from '@/assets/logo.svg?url';
import MenuDarkBackground from '@/assets/menu-dark.svg?url';
import MenuLightBackground from '@/assets/menu-light.svg?url';
import { DoubleRightOutlined } from '@ant-design/icons';

import { useSidebarItems } from '../const';
import { CollapseButton, LogoWrapper, MenuStyled, SiderStyled, ThemeSwitch, Wrapper } from './Menu.styled';

export interface MenuProps {
  collapsed?: boolean;
  onClick?: AntMenuProps['onClick'];
  onClose?: () => void;
  onClickLogo?: () => void;
  toggleCollapsed?: () => void;
}

export const Menu = (props: MenuProps) => {
  const { onClick, collapsed, toggleCollapsed, onClose, onClickLogo } = props;
  const { isDarkMode, toggleTheme } = useTheme();
  const { isDesktop } = useGlobal();
  const location = useLocation();
  const { navigateTo } = useAppNavigation();
  const { logout } = useAuth();

  const [selectedKeys, setSelectedKeys] = useState<string[]>();

  const handleOnClick: MenuProps['onClick'] = (e) => {
    const key = e.key as RoutesMapKeys;
    onClick ? onClick(e) : navigateTo[key]();
    onClose?.();
  };

  const handleClickLogo = () => {
    onClickLogo ? onClickLogo() : navigateTo.landing();
    onClose?.();
  };

  const getBackgroundImage = () => (isDarkMode ? MenuDarkBackground : MenuLightBackground);

  const wicketLogo = useMemo(() => (collapsed ? LogoCollapsed : Logo), [collapsed]);

  useEffect(() => {
    setSelectedKeys([`${location.pathname.split('/')[1]}`]);
  }, [location.pathname]);

  return (
    <Wrapper hasSider $isCollapsed={collapsed} $backgroundImage={getBackgroundImage()}>
      <SiderStyled collapsed={collapsed}>
        <ThemeSwitch checked={isDarkMode} onChange={toggleTheme} title="Switch Theme Mode" $isCollapsed={collapsed} />
        <LogoWrapper $isCollapsed={collapsed} onClick={handleClickLogo}>
          <img src={wicketLogo} alt="logo" />
        </LogoWrapper>
        <MenuStyled
          onClick={handleOnClick}
          defaultSelectedKeys={[`${location.pathname.split('/')[1]}`]}
          selectedKeys={selectedKeys}
          mode="inline"
          items={useSidebarItems()}
          $isCollapsed={collapsed}
          $isDarkMode={isDarkMode}
        />
        {isDesktop && (
          <CollapseButton
            type="primary"
            onClick={toggleCollapsed}
            $isCollapsed={collapsed}
            icon={<DoubleRightOutlined />}
          />
        )}
        <Button onClick={logout}>Logout</Button>
      </SiderStyled>
    </Wrapper>
  );
};
