import { BellOutlined } from '@ant-design/icons';

import styled from 'styled-components';

export const Header = styled.header`
  min-height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colorBgContainer};
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderSecondary};
`;

export const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colorPrimary};
`;

export const StyledBellOutlined = styled(BellOutlined)`
  svg {
    font-size: 24px;
  }
`;
