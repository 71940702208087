import { Button, Layout, Menu as AntMenu, MenuProps as AntMenuProps, Switch } from 'antd';
import styled from 'styled-components';

export const MENU_TOKENS = {
  light: {
    itemSelectedBg: 'rgba(27, 181, 170, 1)',
    itemSelectedColor: 'rgba(255, 255, 255, 1)',
    itemHoverBg: 'rgba(0, 0, 0, 0)',
    itemHoverColor: 'rgba(255, 255, 255, 1)',
    itemColor: 'rgba(255, 255, 255, 0.65)',
    subMenuItemBg: 'rgba(25, 37, 56, 0.6)',
    itemActiveBg: 'rgba(0, 0, 0, 0)',
    popupBg: '#112843',
  },
  dark: {
    itemSelectedBg: 'rgba(19, 49, 52, 1)',
    itemHoverBg: 'rgba(255, 255, 255, 0.12)',
    itemActiveBg: 'rgba(255, 255, 255, 0.12)',
    itemSelectedColor: 'rgba(57, 178, 166, 1)',
    itemHoverColor: 'rgba(255, 255, 255, 0.85)',
    itemColor: 'rgba(255, 255, 255, 0.85)',
    subMenuItemBg: 'rgba(26, 30, 36, 1)',
    popupBg: '#1C2021',
  },
};

interface WrapperProps {
  $isCollapsed?: boolean;
  $backgroundImage?: string;
  style?: React.CSSProperties;
}

export const Wrapper = styled(Layout).attrs(({ $isCollapsed }: WrapperProps) => ({
  style: {
    width: $isCollapsed ? 72 : 264,
    maxWidth: $isCollapsed ? 72 : 264,
    minHeight: '100dvh',
    height: '100%',
  },
}))<WrapperProps>`
  background: url(${({ $backgroundImage }) => $backgroundImage});
  lightgray 50% / cover no-repeat;
`;

interface LogoWrapperProps {
  $isCollapsed?: boolean;
}

export const LogoWrapper = styled.div<LogoWrapperProps>`
  padding: 28px 24px 16px;
  cursor: pointer;
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    `
      max-width: 76px;
      margin: 0 auto;
    `}
`;

interface CollapseButtonProps {
  $isCollapsed?: boolean;
  style?: React.CSSProperties;
}

export const CollapseButton = styled(Button)<CollapseButtonProps>`
  position: absolute;
  bottom: 16px;
  background: transparent;
  color: ${({ theme }) => theme.colorPrimary};
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  right: ${({ $isCollapsed }) => ($isCollapsed ? '50%' : '16px')};
  transform: ${({ $isCollapsed }) => ($isCollapsed ? 'translateX(40%) rotate(0deg)' : 'rotate(180deg)')};
  transition:
    transform 0.3s ease,
    right 0.3s ease;
`;

interface MenuStyledProps extends AntMenuProps {
  $isCollapsed?: boolean;
  $isDarkMode?: boolean;
}

export const MenuStyled = styled(AntMenu).attrs(() => ({
  style: {
    background: 'transparent',
    border: 'none',
  },
}))<MenuStyledProps>`
  .ant-menu-item {
    ${({ $isCollapsed }) =>
      $isCollapsed &&
      `
        max-width: 68px;
        margin-inline: 0px;
        margin-left: 2px;
       `}
  }
  .ant-menu-submenu {
    ${({ $isCollapsed }) =>
      $isCollapsed &&
      `
        max-width: 68px;
        margin-inline: 0px;
        margin-left: 4px;
       `}
  }
  .ant-menu-submenu-open {
    background: ${({ $isDarkMode }) => ($isDarkMode ? '#24282e' : 'rgba(25, 37, 56, 0.60)')};
  }
`;

export const SiderStyled = styled(Layout.Sider).attrs(() => ({
  style: {
    background: 'transparent',
  },
  width: '100%',
}))``;

interface ThemeSwitchProps {
  $isCollapsed?: boolean;
}

export const ThemeSwitch = styled(Switch)<ThemeSwitchProps>`
  position: absolute;
  top: ${({ $isCollapsed }) => ($isCollapsed ? 'calc(100% - 92px)' : '30px')};
  right: ${({ $isCollapsed }) => ($isCollapsed ? '50%' : '28px')};
  transform: translateX(42%);
`;
