import 'antd/dist/reset.css';
import 'antd-mobile/es/global';
import './global.css';

import { ConfigProvider } from 'antd';
import { Outlet } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import Layout from '@/components/Layout';

import SidebarDesktop from './components/Sidebar/SidebarDesktop';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import { GlobalStyles } from './GlobalStyles';
import { useGlobal } from 'src/contexts/GlobalContext';
import { GlobalProvider } from '../src/contexts/GlobalContext';

const App = () => (
  <ThemeProvider>
    <GlobalProvider>
      <AppContent />
    </GlobalProvider>
  </ThemeProvider>
);

const AppContent = () => {
  const { themeAlgorithm, customTheme, combinedTheme } = useTheme();
  const { isDesktop } = useGlobal();

  return (
    <ConfigProvider theme={{ algorithm: themeAlgorithm, ...customTheme }}>
      <StyledThemeProvider theme={combinedTheme}>
        <GlobalStyles theme={combinedTheme} />
        {isDesktop ? <SidebarDesktop /> : null}
        <Layout>
          <Outlet />
        </Layout>
      </StyledThemeProvider>
    </ConfigProvider>
  );
};

export default App;
