import { App } from 'antd';
import { AxiosResponse, AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  FanguardApiContractsPagedItemsModel1FanguardApiContractsPersonPersonSummaryModel,
  FanguardApiContractsPersonPersonBulkAddToWatchlistRequestModel,
  FanguardApiContractsPersonPersonBulkRemoveFromWatchlistRequestModel,
  FanguardApiContractsPersonPersonDetailModel,
  FanguardApiContractsPersonPersonPhotoRequestModel,
  FanguardApiContractsPersonPersonPhotoErrorResponsModel,
  FanguardApiContractsPersonPersonPhotoResponseModel,
} from 'src/api/generated';

import { get, patch, post, remove } from '@/api/api';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { buildQueryParams } from './helpers';
import { useGetList } from './useListsEndpoint';
import { Schema as PersonSchema } from '@/pages/People/Edit/config';

const URI = 'persons';

export const getPersonOptions = (personId: string) => ({
  queryKey: ['person', personId],
  staleTime: 1000 * 60,
  queryFn: () => get<FanguardApiContractsPersonPersonDetailModel>({ uri: `${URI}/${personId}` }),
});

const useGetPerson = (
  personId?: string | null,
): UseQueryResult<AxiosResponse<FanguardApiContractsPersonPersonDetailModel>, Error> => {
  return useQuery({
    ...getPersonOptions(personId ?? ''),
    enabled: !!personId,
  });
};

interface useGetPersonsParams {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  listId?: string;
  photosCountFrom?: number;
  photosCountTo?: number;
  excludeWatchlistId?: string;
}

const useGetPersons = ({
  page = 1,
  pageSize = 25,
  searchTerm = '',
  listId,
  photosCountFrom,
  photosCountTo,
  excludeWatchlistId,
}: useGetPersonsParams) => {
  const params = {
    page,
    pageSize,
    watchlistId: listId,
    searchTerm,
    sortOrder: 'firstName',
    photosCountFrom,
    photosCountTo,
    excludeWatchlistId,
  };

  return useQuery({
    queryKey: ['persons', params],
    staleTime: 1000 * 60,
    queryFn: async () => {
      const response = await get<FanguardApiContractsPagedItemsModel1FanguardApiContractsPersonPersonSummaryModel>({
        uri: `${URI}?${buildQueryParams(params)}`,
      });

      return response.data;
    },
  });
};

interface AddPersonToListPayloadType {
  personIds: string[];
  watchlistId: string;
}

interface useAddPersonsToListPayloadType {
  onSuccess?: () => void;
  onError?: () => void;
}

const useAddPersonsToList = (payload: useAddPersonsToListPayloadType) => {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<FanguardApiContractsPersonPersonBulkAddToWatchlistRequestModel>,
    Error,
    AddPersonToListPayloadType
  >({
    mutationFn: (data: FanguardApiContractsPersonPersonBulkAddToWatchlistRequestModel) =>
      post({ uri: `${URI}/watchlistAdd`, data }),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['persons'] });
      payload?.onSuccess && payload.onSuccess();
    },
    onError: () => {
      payload?.onError && payload.onError();
    },
  });
};

interface DeletePersonsFromListParams {
  personIds: string[];
  watchListId?: string;
  listName: string;
  personName?: string;
}

const useDeletePersonsFromList = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { t } = useTranslation();

  return useMutation<
    AxiosResponse<FanguardApiContractsPersonPersonBulkRemoveFromWatchlistRequestModel>,
    Error,
    DeletePersonsFromListParams
  >({
    mutationFn: ({ personIds, watchListId }: DeletePersonsFromListParams) =>
      post({
        uri: `${URI}/watchlistremove`,
        data: {
          personIds,
          watchListId,
        },
      }),
    onSuccess: (_data, variables) => {
      const { personIds, listName, personName } = variables;
      const msg =
        personIds.length > 1 ? `${personIds.length} ${t('people.people').toLocaleLowerCase()}` : `${personName}`;
      message.success(`${t('general.removed')} ${msg} ${t('general.from')} ${listName}.`);

      queryClient.invalidateQueries({
        queryKey: ['persons'],
      });
    },
    onError: (_data, variables) => {
      const { personIds, listName, personName } = variables;
      const msg =
        personIds.length > 1
          ? `${t('general.unableToRemove')} ${personIds.length} ${t('people.people').toLocaleLowerCase()}`
          : `${t('general.unableToRemove')} ${personName}`;
      message.error(`${msg} ${t('general.from')} ${listName}.`);
    },
  });
};

interface DeletePersonParams {
  personId: string;
}

const useDeletePerson = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ personId }: DeletePersonParams) =>
      remove({
        uri: `${URI}/${personId}`,
      }),
    onSuccess: () => {
      message.success(`${t('general.deleted')} 1 ${t('people.people').toLowerCase()}.`);
      queryClient.invalidateQueries({
        queryKey: ['persons'],
      });
    },
    onError: () => {
      message.error(`${t('general.unableToDelete')} ${t('people.people').toLowerCase()}.`);
    },
  });
};

const useUpdatePerson = (id?: string) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: PersonSchema) =>
      patch({
        uri: `${URI}/${id}`,
        data,
      }),
    onSuccess: () => {
      message.success(`${t('people.person')} ${t('general.updateSuccess')}`);
      queryClient.invalidateQueries({
        queryKey: ['persons'],
      });
      queryClient.invalidateQueries({ queryKey: ['person', id] });
    },
    onError: () => {
      message.error(`${t('general.updateFailure')} ${t('people.person').toLowerCase()}.`);
    },
  });
};

const useCreatePerson = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<FanguardApiContractsPersonPersonPhotoResponseModel>, Error, PersonSchema>({
    mutationFn: (data: PersonSchema) =>
      post({
        uri: `${URI}`,
        data,
      }),
    onSuccess: () => {
      message.success(`${t('people.person')} ${t('general.createSuccess')}`);
      queryClient.invalidateQueries({
        queryKey: ['persons'],
      });
    },
    onError: () => {
      message.error(`${t('general.createFailure')} ${t('people.person').toLowerCase()}.`);
    },
  });
};

const useImportPersons = (watchlist?: string) => {
  // const { message } = App.useApp();
  // const { t } = useTranslation();
  const { data: listData } = useGetList(watchlist);

  return useMutation({
    mutationFn: async ({ file, includesHeaderRow }: { file: File; includesHeaderRow: boolean }) => {
      const payload = {
        watchlistId: watchlist,
        watchlistName: listData?.data?.name || '',
        includesHeaderRow,
        columnMapping: ['FirstName', 'LastName', 'Email', 'Photos', ''],
      };

      const formData = new FormData();
      formData.append('json', JSON.stringify(payload));
      formData.append('file', file, file.name);
      // return post({
      //   uri: `${URI}/import`,
      //   data: formData,
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
    },
    onSuccess: (data, variables) => {
      console.log(data, 'data');
      console.log(variables, 'variables');
      // queryClient.invalidateQueries({
      //   queryKey: ['persons'],
      // });
    },
    onError: (error, variables) => {
      console.log(error, 'error');
      console.log(variables, 'variables');
    },
  });
};

const useDeletePersonPhoto = (personId: string) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (photoId: string) =>
      remove({
        uri: `${URI}/${personId}/photos/${photoId}`,
      }),
    onSuccess: () => {
      message.success(`${t('general.deleted')} ${t('general.photo').toLowerCase()}.`);
      queryClient.invalidateQueries({ queryKey: ['person', personId] });
    },
    onError: () => {
      message.error(`${t('general.unableToDelete')} ${t('general.photo').toLowerCase()}.`);
    },
  });
};

const useUploadPersonPhoto = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({
      personId,
      data,
    }: {
      personId: string | undefined | null;
      data: FanguardApiContractsPersonPersonPhotoRequestModel;
    }) => {
      return post({
        uri: `${URI}/${personId}/photos`,
        data,
      });
    },
    onSuccess: (_, variables) => {
      message.success(`${t('general.photo')} ${t('general.uploadSuccess').toLowerCase()}`);
      queryClient.invalidateQueries({ queryKey: ['person', variables.personId] });
    },
    onError: (error: AxiosError<FanguardApiContractsPersonPersonPhotoErrorResponsModel>) => {
      message.error(error.response?.data.defaultMessage ?? `${t('general.somethingWentWrong')}`);
    },
  });
};

export {
  useAddPersonsToList,
  useDeletePerson,
  useUpdatePerson,
  useCreatePerson,
  useDeletePersonsFromList,
  useDeletePersonPhoto,
  useGetPerson,
  useGetPersons,
  useImportPersons,
  useUploadPersonPhoto,
};
