import { Drawer } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-drawer-body {
    padding: 0px;
    min-height: 100dvh;
  }
`;

export const MenuWrapper = styled.div`
  min-height: 100dvh;
`;

export const StyledDrawer = styled(Drawer).attrs({
  styles: {
    wrapper: {
      width: 'fit-content',
    },
  },
})``;
