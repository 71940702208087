import { FanguardApiContractsClientVenueSummaryModel } from 'src/api/generated';
import { get } from '@/api/api';
import { useQuery } from '@tanstack/react-query';

const URI = 'venues';

const useGetVenues = () => {
  return useQuery({
    queryKey: [URI],
    queryFn: async () => {
      const response = await get<FanguardApiContractsClientVenueSummaryModel[]>({ uri: URI });

      return response.data;
    },
  });
};

export { useGetVenues };
