import { AliasToken } from 'antd/es/theme/interface';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ theme: AliasToken }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background-color: ${({ theme }) => theme.colorBgLayout};
    color: ${({ theme }) => theme.colorTextBase};
    margin: 0;
    padding: 0;
    overscroll-behavior: none;
    overflow: hidden;
  }
  .ant-app {
    display: flex;
  }
  .ant-table-filter-dropdown {
    border: ${({ theme }) => `solid ${theme.colorBorderSecondary} 1px`};
  }
`;
