import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden;
`;

interface ContentProps {
  $isDesktop: boolean;
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 100%;
  padding-top: ${({ $isDesktop }) => ($isDesktop ? '0' : '16px')};
`;
