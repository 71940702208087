import { Flex } from 'antd';
import styled from 'styled-components';

export const FlexGap8 = styled(Flex).attrs({
  gap: 8,
})``;

export const FlexGap16 = styled(Flex).attrs({
  gap: 16,
})``;

export const FlexGap8JustifyEnd = styled(Flex).attrs({
  gap: 8,
  justify: 'flex-end',
})``;

export const FlexGap16AlignCenter = styled(Flex).attrs({
  gap: 16,
  align: 'center',
})``;
