import { useGlobal } from 'src/contexts/GlobalContext';

import { AppHeader } from '../AppHeader/AppHeader';
import AppBreadcrumbs from '../Breadcrumbs';
import { Content, Wrapper } from './Layout.styled';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { isDesktop } = useGlobal();
  return (
    <Wrapper>
      <AppHeader />
      {isDesktop && <AppBreadcrumbs />}
      <Content $isDesktop={isDesktop}>{children}</Content>
    </Wrapper>
  );
};
