import i18n from 'i18next';
import { getListOptions } from 'src/hooks/api/useListsEndpoint';
import { getPersonOptions } from 'src/hooks/api/usePersonsEndpoint';
import { queryClient } from 'src/main';
import { routesMap } from 'src/routes';

const STATIC_LIST_PATHS = [
  { path: 'new', title: i18n.t('lists.newList') },
  { path: 'edit', title: i18n.t('people.editList') },
];

const STATIC_PEOPLE_PATHS = [
  { path: 'new', title: i18n.t('people.addPerson') },
  { path: 'edit', title: i18n.t('people.editPerson') },
];

export const getListName = async (id: string): Promise<string | undefined> => {
  if (STATIC_LIST_PATHS.some((path) => path.path === id)) {
    return STATIC_LIST_PATHS.find((path) => path.path === id)?.title || 'N/A';
  } else {
    try {
      const data = await queryClient.fetchQuery(getListOptions(id));
      return data?.data?.name;
    } catch (error) {
      console.log(error);
      return 'N/A';
    }
  }
};

export const getPeopleName = async (id: string): Promise<string | undefined> => {
  if (STATIC_PEOPLE_PATHS.some((path) => path.path === id)) {
    return STATIC_PEOPLE_PATHS.find((path) => path.path === id)?.title || 'N/A';
  } else {
    try {
      const data = await queryClient.fetchQuery(getPersonOptions(id));
      const displayName = `${data?.data?.firstName} ${data?.data?.lastName}`;
      return displayName;
    } catch (error) {
      console.log(error);
      return 'N/A';
    }
  }
};

export const getBreadcrumbsMap = (): {
  [key: string]: string | ((id: string) => Promise<string | undefined>);
} => {
  const dynamicRoutes = {
    [routesMap.listDetails]: async (id: string) => await getListName(id),
    [routesMap.listDetailsAddPeople]: async () => `${i18n.t('general.add')} ${i18n.t('people.people')}`,
    [routesMap.peopleDetails]: async (id: string) => await getPeopleName(id),
    [routesMap.peopleDetailsEdit]: async () => i18n.t('people.editPerson'),
  };

  const staticBreadcrumbs: { [key: string]: string } = {
    [routesMap.users]: 'Users',
    [routesMap.settings]: 'Settings',
    [routesMap.reports]: 'Reports',
    [routesMap['ticket-logs']]: 'Ticket Logs',
    [routesMap['payment-logs']]: 'Payment Logs',
    [routesMap['access-logs']]: 'Access Logs',
    [routesMap.lists]: 'Lists',
    [routesMap.listNew]: 'New List',
    [routesMap.people]: 'People',
    [routesMap.venues]: 'Venues',
    [routesMap.devices]: i18n.t('devices.sensors.title'),
    [routesMap.groups]: i18n.t('devices.groups.title'),
    [routesMap.events]: 'Events',
    [routesMap.account]: 'Account',
    [routesMap.preferences]: 'Preferences',
    [routesMap.kiosk]: 'Kiosk',
    [routesMap.connections]: 'Connections',
    [routesMap.sites]: 'Sites',
    [routesMap.emailTemplates]: 'Email Templates',
    [routesMap.ticketGroups]: 'Ticket Groups',
    [routesMap.noPermission]: 'No Permission',
    [routesMap.login]: 'Login',
    [routesMap.assets]: 'Assets',
  };

  return { ...staticBreadcrumbs, ...dynamicRoutes };
};
