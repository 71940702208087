import { Avatar, Button, Typography } from 'antd';
import { useGlobal } from 'src/contexts/GlobalContext';

import { useGetUser } from '@/hooks/api/useUsersEndpoint';
import { UserOutlined } from '@ant-design/icons';

import SidebarMobile from '../Sidebar/SidebarMobile';
import { Header, StyledBellOutlined } from './AppHeader.styled';
import VenuesDropdown from './VenuesDropdown';
import { FlexGap16 } from '../CommonStyledComponents/CommonStyledComponents.styled';

export const AppHeader = () => {
  const { isDesktop } = useGlobal();
  const { data: userData, isSuccess } = useGetUser();

  const fullName = isSuccess ? `${userData?.data?.firstName} ${userData?.data?.lastName}` : '';

  const onBellHandler = () => console.log('bell clicked');

  return (
    <Header>
      {!isDesktop ? <SidebarMobile /> : null}
      <VenuesDropdown />
      <FlexGap16 align="center">
        <Button type="text" onClick={onBellHandler} icon={<StyledBellOutlined />} />
        {isDesktop ? <Typography.Text>{fullName}</Typography.Text> : null}

        <Avatar src="" icon={<UserOutlined />} />
      </FlexGap16>
    </Header>
  );
};
