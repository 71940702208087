import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

type DeviceType = 'mobile' | 'tablet' | 'desktop';

interface GlobalContextProps {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

const determineDeviceType = () => {
  if (window.innerWidth < 768) {
    return 'mobile';
  } else if (window.innerWidth < 1024) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

const GlobalContext = createContext<GlobalContextProps | null>(null);

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [deviceType, setDeviceType] = useState<DeviceType>(determineDeviceType());

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setDeviceType('mobile');
      } else if (window.innerWidth < 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const memoValue = useMemo(() => {
    return {
      isMobile: deviceType === 'mobile',
      isTablet: deviceType === 'tablet',
      isDesktop: deviceType === 'desktop',
    };
  }, [deviceType]);

  return <GlobalContext.Provider value={memoValue}>{children}</GlobalContext.Provider>;
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobal must be used within an GlobalProvider');
  }
  return context;
};
